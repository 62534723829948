import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div>
      <div>
        <Link to="/about/">About Us</Link>
      </div>
    </div>
      <iframe src="https://cdn.forms-content.sg-form.com/cd8e7fbb-c68b-11e9-ba70-eed2a5dfd7e5"/>
  </Layout>
)

export default IndexPage
